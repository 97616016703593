<template>
  <div class="favorite-container">
    <page-top :title="$t('SalesRankings.title')">
      <span class="fa fa-bookmark fa-2x"></span>
    </page-top>
    <el-card>
      <el-form :inline="true" :model="search">
        <el-form-item>
          <el-date-picker
              v-model="search.datetime"
              style="width:100%;"
              type="daterange"
              value-format="timestamp"
              @change="changeDatetime"
              range-separator="~"
              :start-placeholder="$t('placeholder.524620@start_time')"
              :end-placeholder="$t('placeholder.ABB7CF@end_time')"
              :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <el-table
          :data="tableData"
          :row-class-name="$tableRowClassName"
          style="width: 100%;"
          v-loading="loading"
          :element-loading-text="$t('public.4ABAB1@loading')"
          :header-cell-style="{'text-align':'center',}"
          :cell-style="{'text-align': 'center',}"
          @sort-change="changeSort"
          :default-sort="{prop: 'total_price', order: 'descending'}">
        <!--    第二層    -->
        <el-table-column type="expand">
          <template slot-scope="data">
            <el-table
                border
                :data="data.row.list"
                :row-class-name="$tableRowClassName"
                style="width: 100%;"
                :element-loading-text="$t('public.4ABAB1@loading')"
                :header-cell-style="{'text-align':'center',}"
                :cell-style="{'text-align': 'center',}">
              <!--   訂單編號     -->
              <el-table-column prop="order_no"
                               :label="$t('placeholder.5C894A@order_code')">
                <template slot-scope="scope">
                  <a :href="`${domain}/app/orderDetail?order_no=${scope.row.order_no}`">{{ scope.row.order_no }}</a>
                </template>
              </el-table-column>
              <!--   購買公司名稱     -->
              <el-table-column prop="receiver_company_name"
                               :label="$t('backend_shop_supplierList.995038@company_name')">
              </el-table-column>
              <!--   餐廳名稱     -->
              <el-table-column prop="buyer_shop_name"
                               :label="$t('aboutus.E8221A@restaurant_name')">
              </el-table-column>
              <!--   產品數量     -->
              <el-table-column prop="receive_num"
                               :label="$t('orderDetail.CAF2C7@number')">
                <template slot-scope="scope">
                  {{ FormatNumber(scope.row.receive_num, 3) }}
                </template>
              </el-table-column>
              <!--   總金額     -->
              <el-table-column prop="final_goods_price"
                               :label="$t('orderDetail.7CA68E@total_price')">
                <template slot-scope="scope">
                  {{ `$${FormatNumber(scope.row.final_goods_price)}` }}
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <!--   排行     -->
        <el-table-column type="index" :label="$t('SalesRankings.rankings')" width="100">
          <template slot-scope="scope">
            <span v-if="sort==2">{{ `Top ${scope.$index + 1 + (currentPage - 1) * pageSize}` }}</span>
            <span v-else>{{ `Top ${total - scope.$index - (currentPage - 1) * pageSize}` }}</span>
          </template>
        </el-table-column>
        <!--   產品名稱     -->
        <el-table-column prop="goods_name"
                         :label="$t('restaurant_supplier_addSupProduct.E9513A@product_name')">
          <template slot-scope="scope">
            <a :href="`${domain}/app/supplier/publishProduct?id=${scope.row.gid}`">{{ scope.row.goods_name }}</a>
          </template>
        </el-table-column>
        <!--   購買總數量     -->
        <el-table-column prop="receive_num" :label="$t('aboutus.3A6FE5@product_num')" sortable>
          <template slot-scope="scope">
            {{ FormatNumber(scope.row.receive_num, 3) }}
          </template>
        </el-table-column>
        <!--    購買總金額    -->
        <el-table-column prop="total_price" :label="$t('aboutus.09C1E0@Total_amount_of_goods')" sortable>
          <template slot-scope="scope">
            {{ `$${FormatNumber(scope.row.total_price)}` }}
          </template>
        </el-table-column>
      </el-table>
      <section style="margin-top: 20px; display: flex; justify-content: flex-end">
        <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[10, 25, 50, 100]"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
      </section>
    </el-card>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";

export default {
  name: "",
  components: {
    pageTop,
  },
  data() {
    return {
      domain: window.location.origin,
      total: 0,
      //1=total_price, 2=receive_num
      sort_type: 1,
      //1=asc, 2=desc
      sort: 2,
      pageSize: 10,
      currentPage: 1,
      search: {
        datetime: "",
      },
      tableData: null,
      loading: true,
    }
  },
  methods: {
    //千分位
    FormatNumber(num, n = 2) {
      let str = num.toFixed(n).toString()
      let reg = str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g
      return str.replace(reg, "$1,")
    },
    changeSort({column, prop, order}) {
      this.sort_type = prop == "total_price" ? 1 : 2;
      this.sort = order == "ascending" ? 1 : 2;
      this.currentPage = 1
      this.getTableData()
    },
    changeDatetime(val) {
      this.search.datetime = val || "";
      this.currentPage = 1
      this.getTableData()
    },
    getTableData() {
      this.$http.getTopGoodsList({
        size: this.pageSize,
        page: this.currentPage,
        start: this.search.datetime[0] / 1000 || 0,
        end: this.search.datetime[1] / 1000 || 0,
        limit: this.pageSize,
        sort_type: this.sort_type,
        sort: this.sort,
      }).then(res => {
        this.tableData = res.data.data
        this.total = res.data.total
        this.loading = false
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getTableData()
    },
  },
  created() {
    this.getTableData()
  },
}
</script>

<style lang="scss" scoped>
.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;

  .pagination {
    float: right;
  }
}
</style>